import AnalysisCard from "../ToolsCard.vue";
import NeoLoader from "@/components/loader";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import OsintAxios from "@/axios/osint.js";
import rawAxios from "@/axios";
import DomainSection from "@/components/domain-section";
import DomainHistorySection from "@/components/domain-history-section";
import {DateTime} from "luxon";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import NeoPagination from "@/components/pagination";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import NeoTabsSwitch from "@/components/tabs-switch";
import {startCase} from "lodash";

export default {
    name: "domain-search",
    components: {
        AnalysisCard,
        NeoLoader,
        NeoInput,
        NeoButton,
        VueMultiselect,
        NeoAnalyse,
        DomainSection,
        DomainHistorySection,
        "download-csv": JsonCSV,
        NeoPagination,
        neoSwitch,
        "neo-tabs-switch": NeoTabsSwitch,
    },
    mixins: [toolsHelper],
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            inputValue: {
                text: "Domain",
                value: "Domain",
            },
            inputOptions: [
                {
                    group: "Field Type",
                    categories: [
                        {
                            text: "Email",
                            value: "Email",
                        },
                        {
                            text: "Domain",
                            value: "Domain",
                        },
                        {
                            text: "Registrant",
                            value: "Registrant",
                        },
                    ],
                },
            ],
            tabs: [
                {
                    name: "Domain Current Details",
                    value: "currentResults",
                },
                {
                    name: "Domain History Details",
                    value: "historyResults",
                },
            ],
            selectedTab: "currentResults",
            estimatedDomainAge: null,
            case_id: "",
            valueInput: "",
            allData: [],
            historyData: [],
            csvData: {
                domain: [],
            },
            currentDetails: true,
            historyDetails: true,
            isLoading: false,
            selectedItem: null,
            emailList: [],
            registrantUsers: [],
            domainList: [],
            searchedType: "",
            showResult: false,
            searchedValue: "",
            triggerRoute: true,
            selectVal: "",
            currentPage: 1,
            perPage: 10,
            searchKeyword: "",
            searchClicked: false,
            tabName: "",
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseRegistrants", "getMonitoringDateFilter", "getSelectedToolQuery", "getProduct"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return ["domain", "created_date", "registrar", "domaindata"];
        },

        outputType() {
            if (this.inputValue.value == "Domain") {
                return "domain";
            } else {
                return "standard";
            }
        },
        getTotalPages() {
            return Math.ceil(this.getAllData.length / this.perPage);
        },
        getAllData() {
            this.currentPage = 1;
            return this.allData.filter((ele) => ele.domain?.toLowerCase().includes(this.searchKeyword.toLowerCase()) || ele.registrar?.toLowerCase().includes(this.searchKeyword.toLowerCase()) || ele.registrar?.toLowerCase().includes(this.searchKeyword.toLowerCase()));
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        checkMarkAllDone() {
            if (this.allData && this.allData.length) return this.allData.every((result) => result.changes_viewed);
            else return false;
        },
    },
    async mounted() {
        this.tabName = this.$route.name;
        this.case_id = this.$route.params.id;
        const dataRes = this.getToolsData(this.tabName, "domain-search-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        // this.SET_ALL_TOOLS_DATA({
        //     ...this._data,
        //     "tool-name": "domain-search-tool",
        // });
        this.setToolsData(this.tabName, "domain-search-tool");
    },
    watch: {
        // allData(newVal, prevVal) {
        //     if (newVal) {
        //         this.$emit("searchResult", { data: newVal, source: "domain-search", searchType: "email" });
        //     }
        // },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },

    methods: {
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAnalysisDropdownData", "getAllCaseRegistrants"]),

        async getToolData() {
            this.emailList = await this.getDropdownData("email");
            this.registrantUsers = await this.getDropdownData("registrant");
            this.domainList = await this.getDropdownData("domain");
        },
        changeSelectedTab(tabName) {
            this.selectedTab = tabName;
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list === "registrantUsers" ? "Registrant" : list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "domain-search", [...this.emailList, ...this.registrantUsers, ...this.domainList], data);
            allChecked = [...this.emailList, ...this.registrantUsers, ...this.domainList]?.length ? ([...this.emailList, ...this.registrantUsers, ...this.domainList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        updateFieldType() {
            this.selectedItem = null;
            this.valueInput = "";
            this.allData = [];
            this.historyData = [];
            this.selectVal = "";
        },

        setInput() {
            this.searchType = "email";
            this.valueInput = this.selectedItem.value;
        },

        setTheCustomInput(value) {
            this.searchType = "email";
            this.valueInput = value;
            this.selectedItem = {
                value: value,
            };
        },

        async setSearchedUser(searchKeyword) {
            let val_type = "";
            if (this.inputValue.value === "Email") {
                val_type = "email";
            } else if (this.inputValue.value === "Domain") {
                val_type = "domain";
            } else if (this.inputValue.value === "Registrant") {
                val_type = "registrant";
            }
            const data = {
                value: searchKeyword,
                platform: "domain-search",
                val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let type = val_type;
            let listName = type !== "registrant" ? `${type}List` : "registrantUsers";
            let dataItem = this[listName].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[listName].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        manualSearch() {
            this.triggerRoute = false;
            this.handleSearch();
        },

        async handleSearch() {
            this.searchKeyword = "";
            this.estimatedDomainAge = null;
            this.currentPage = 1;
            if (this.valueInput) {
                this.showResult = false;
                this.searchedType = this.outputType;
                this.searchedValue = this.valueInput;
                this.searchClicked = true;
                this.currentDetails = true;
                this.historyDetails = true;
                this.selectedTab = "currentResults";

                if (this.isMonitoringTab) {
                    this.monitoringSearch();
                    return;
                }
                const domainRegex = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;

                if (this.inputValue.value == "Domain") {
                    if (domainRegex.test(this.valueInput)) {
                        const obj = {
                            domain: this.valueInput,
                        };
                        this.$emit("searched", {data: {domain: this.valueInput}, source: "domain-search"});
                        this.isLoading = true;
                        try {
                            const {data} = await OsintAxios.post("/domain/registration/lookup", obj, {
                                headers: {
                                    "x-tool-name": this.$route.params.toolName,
                                    "x-visited": true,
                                },
                            });
                            this.request_id = data?.query_id || data?.request_id;
                            if (data.status) {
                                this.allData = data.data;
                                this.graphData = [{company: {name: this.valueInput}}];
                                if (this.graphData) {
                                    this.graphData[0].company = {...this.graphData[0].company, domainData: this.allData};
                                    // this.graphData[0].company = { ...this.graphData[0].company, registrant: this.domainData.registrant};
                                    // this.graphData[0].company = { ...this.graphData[0].company, technicalContact: this.domainData.technicalContact};
                                    this.$emit("searchResult", {data: this.graphData, source: "domain-search", searchType: "domain-search"});
                                }
                                const years = Math.floor(this.allData.estimatedDomainAge / 365);
                                const months = Math.floor((this.allData.estimatedDomainAge - years * 365) / 30);
                                const days = Math.round(this.allData.estimatedDomainAge - years * 365 - months * 30);
                                let res = [];
                                if (years > 0) {
                                    res.push(years + " years");
                                }
                                if (months > 0) {
                                    res.push(months + " months");
                                }
                                if (days > 0) {
                                    res.push(days + " days");
                                }

                                this.estimatedDomainAge = res.join(", ");
                                this.csvData.domain = this.convertToCSV(this.allData);
                                this.showResult = true;
                                this.SET_ALL_TOOLS_DATA({
                                    ...this._data,
                                    "tool-name": "domain-search-tool",
                                });
                            } else {
                                this.showResult = true;
                                this.allData = [];
                                this.$toast.error(data.message || "No data found");
                            }
                        } catch (e) {
                            if (e.response && e.response.status !== 406) this.$toast.error("Domain Current Details: Something went wrong");
                            this.currentDetails = false;
                        }
                        try {
                            const {data: historyData} = await OsintAxios.post("/domain/registration/domain-history", obj, {
                                headers: {
                                    "x-tool-name": this.$route.params.toolName,
                                    "x-visited": true,
                                    "x-query-id": this.request_id,
                                },
                            });
                            if (historyData.status) {
                                this.historyData = historyData.data;
                                const history = this.historyData.map((el) => ({...el, isexpanded: false}));
                                this.historyData = history;
                                if (this.graphData) {
                                    this.graphData[0].company = {...this.graphData[0].company, domainData: this.historyData};
                                    // this.graphData[0].company = { ...this.graphData[0].company, registrant: this.domainData.registrant};
                                    // this.graphData[0].company = { ...this.graphData[0].company, technicalContact: this.domainData.technicalContact};
                                    this.$emit("searchResult", {data: this.graphData, source: "domain-search", searchType: "domain-search"});
                                }
                                this.showResult = true;
                            } else {
                                this.showResult = true;
                                this.historyData = [];
                                this.$toast.error(historyData.message || "No data found");
                            }
                            if (!this.request_id) this.request_id = historyData?.data?.query_id;
                        } catch (e) {
                            if (e.response && e.response.status !== 406) this.$toast.error("Domain History Details: Something went wrong");
                            this.historyDetails = false;
                        }
                        this.isLoading = false;

                        await this.setSearchedUser(this.valueInput);

                        this.redirectTo();
                    } else {
                        this.$toast.error("Please enter a valid input");
                    }
                    // this.graphData = [{ company: { name: this.searchKeyword } }];

                    //this.$emit("searchResult", { data: this.allData, source: "domain-search", searchType: "domain" });
                } else {
                    let val_type, nodeType;
                    let validInput = false;
                    const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                    if (this.inputValue.value === "Email" && emailRegex.test(this.valueInput.trim())) {
                        val_type = "email";
                        nodeType = "email";
                        validInput = true;
                    } else if (this.inputValue.value === "Registrant") {
                        val_type = "query";
                        nodeType = "name";
                        validInput = true;
                    }
                    if (validInput == true) {
                        const obj = {
                            [val_type]: this.valueInput.trim(),
                        };
                        this.$emit("searched", {data: {[nodeType]: this.valueInput.trim()}, source: "domain-search"});
                        this.isLoading = true;
                        // this.triggerRoute = true;
                        const {data} = await OsintAxios.post("/domain/lookup", obj, {
                            headers: {
                                "x-tool-name": this.$route.params.toolName,
                                "x-visited": true,
                            },
                        });
                        this.isLoading = false;
                        await this.setSearchedUser(this.valueInput.trim());
                        if (data.status) {
                            this.showResult = true;
                            this.allData = data.data;
                            this.csvData.domain = this.convertToCSV(this.allData);
                            this.SET_ALL_TOOLS_DATA({
                                ...this._data,
                                "tool-name": "domain-search-tool",
                            });
                        } else {
                            this.showResult = true;
                            this.allData = [];
                            this.$toast.error(data.message || "No data found");
                        }
                        this.request_id = data?.query_id || data?.request_id;
                        this.redirectTo();
                        this.$emit("searchResult", {data: this.allData, source: "domain-search", searchType: "email"});
                    } else {
                        this.$toast.error("Please enter a valid input");
                    }
                }
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async monitoringSearch() {
            let sources = ["domain-search-tool"];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            if (values) values = values.map((val, ind) => ({...val, currIndex: ind}));
            this.showResult = true;
            if (this.searchedType === "domain") {
                this.allData = values[0];
            } else if (this.searchedType === "standard") {
                this.allData = values;
            } else {
                this.allData = values;
            }

            this.csvData.domain = this.convertToCSV(this.allData);
        },

        getFormattedTime(ts, format = "EEE, dd MMM yyyy, hh:mm:ss a") {
            let dt = DateTime.fromISO(ts);
            if (dt.toFormat(format) != "Invalid DateTime") return dt.toFormat(format);
            else return "";
        },
        getResultFormattedTime(ts, format = "dd MMM yyyy") {
            let dt = DateTime.fromISO(ts);
            return dt.toFormat(format);
        },
        async fetchMoreData(piData, pi_index) {
            this.estimatedDomainAge = null;
            piData.expanded = !piData.expanded;
            this.$emit("searched", {data: {domain: piData.domain}, source: "domain-search"});
            this.$forceUpdate();
            if (!piData.newdata) {
                piData.loading = true;
                this.$forceUpdate();
                const {data} = await OsintAxios.post(
                    "/domain/registration/lookup",
                    {
                        domain: piData.domain,
                    },
                    {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    }
                );
                this.request_id = data?.query_id || data?.request_id;
                if (data.status) {
                    piData.newdata = true;
                    piData.loading = false;
                    piData.expanded = true;
                    piData.domaindata = data.data;
                    const years = Math.floor(piData.domaindata.estimatedDomainAge / 365);
                    const months = Math.floor((piData.domaindata.estimatedDomainAge - years * 365) / 30);
                    const days = Math.round(piData.domaindata.estimatedDomainAge - years * 365 - months * 30);
                    let res = [];
                    if (years > 0) {
                        res.push(years + " years");
                    }
                    if (months > 0) {
                        res.push(months + " months");
                    }
                    if (days > 0) {
                        res.push(days + " days");
                    }

                    this.estimatedDomainAge = res.join(", ");
                    this.$emit("searchResult", {data: [piData.domaindata], source: "domain-search", searchType: "domain"});
                } else {
                    piData.loading = false;
                }
                this.$forceUpdate();
            }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];
                const type = Object.keys(selected_query?.query)[0];

                if (value && type) {
                    this.valueInput = value;
                    this.selectedItem = {
                        value: value,
                    };
                    this.selectVal = value;
                    this.inputValue = {
                        text: startCase(type) == "Query" ? "Registrant" : startCase(type),
                        value: startCase(type) == "Query" ? "Registrant" : startCase(type),
                    };

                    if (!dataRes) this.handleSearch();
                    else this.redirectTo();
                } else if (this.searchClicked) {
                    if (!dataRes) this.handleSearch();
                    else this.redirectTo();
                }
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.selectedItem = {
                value: this.selectVal,
            };
            this.valueInput = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
            // this.$set(this.selectVal, type,event)
        },
        onSelectFieldType() {
            this.showResult = false;
            this.selectedItem = null;
            this.selectVal = "";
            this.$refs["analysis-tool-input"].search = "";
        },
        handlePageChange(page) {
            this.currentPage = page;
            document.querySelector(".domain-search-results").scrollTo(0, 0);
        },

        async handleDone({target}, index) {
            let doc_id = this.allData[index].monitoring_id;
            let sources = ["domain-search-tool"];
            this.allData[index].changes_viewed = !this.allData[index].changes_viewed;
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id,
                visited: targetthis.graph.checked,
            });
        },
        async handleMonitoring(index) {
            let card_source = "domain-search-tool";
            let card_id = this.allData[index].doc_id;
            let status = this.allData[index].monitoring_status;

            this.allData[index].monitoring_status = !this.allData[index].monitoring_status;
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.case_id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source,
                status: status ? "enabled" : "disabled",
            });
        },
        /// MARK ALL AS DONE
        async handleMarkAsAllDone(event) {
            this.allData.forEach((result, index) => {
                this.allData[index].changes_viewed = event.target.checked;
            });
            this.$forceUpdate();
            await axios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: ["domain-search-tool"],
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },

        updateDomainDetail(event) {
            this.allData[event] = !this.allData[event];
        },
    },
};
